<template>
  <div class="page-not-found">
    <div>404 - Page Not Found</div>
    <!--<ul>
      <li><router-link to="/">Home</router-link></li>
      <li><router-link to="/">News</router-link></li>
      <li><router-link to="/">Works</router-link></li>
      <li><router-link to="/">Archive</router-link></li>
      <li><router-link to="/">About</router-link></li>
    </ul>-->
  </div>
</template>

<style>
.page-not-found {
  display: table;
  width: 100%;
  height: 100%;
  background-image: url("~@/assets/img/notfound.jpg");
  background-repeat: no-repeat;
  background-position: center;
}

.page-not-found > div {
  display: table-cell;
  vertical-align: middle;
  font-weight: bold;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
}

.page-not-found figcaption {
  position: absolute;
  display: block;
  left: 40%;
  top: 49%;
  text-align: center;
  font-weight: bold;
  color: #fff;
  text-transform: uppercase;
}

.page-not-found figcaption ul li {
  display: inline-block;
  margin: 0 0.5rem;
}

.figcaption {
  position: absolute;
  /* VERTICAL CENTER */
  top: 50%;
  left: 0;
  /* HORIZONTAL CENTER */
  width: 100%;
  text-align: center;
  /* COSMETICS */
  color: blue;
  font-size: 22px;
  font-weight: 700;
}

/*

figure {
   display:table;
}
figure figcaption {
   display:table-cell;
   vertical-align:middle;
}
figure img {
   display:table-cell;
}*/
</style>
